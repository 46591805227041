import MinimalDinheirowLogo from "@/assets/SVG/minimal_logo/dinheirow.svg";
import DinheirowLogo from "@/assets/SVG/logos/dinheirow-logo.svg";
import { IBrand } from "@/types/whitelabel";
import defaultLoginImage from "@/assets/images/login-image.webp";
import { EMessageSendingType } from "@/constants/MessageSendingTypes";

const finalizedAnalysis = {
  title: "Parabéns!",
  description:
    "Sua análise foi aprovada!\n" +
    "Em caso de dúvida, não hesite em entrar em contato com um dos atendentes.",
};

const dinheirow: IBrand = {
  brand: "dinheirow",
  loginImages: [defaultLoginImage],
  bankOrigin: "dinheirow",
  pageTitle: "Dinheirow",
  partnerCode: "partner_dinheirow",
  favicon: "favicon.ico",
  minimalLogo: { src: MinimalDinheirowLogo, height: 40, width: 40 },
  logo: { src: DinheirowLogo, width: 80, height: 80 },
  logoOnWhite: { src: DinheirowLogo, width: 80, height: 80 },
  loanValues: {
    min: 300,
    max: 60000,
    cardValues: [5000, 10000, 20000, 30000, 50000],
  },
  contactEmail: "contato@dinheirow.com.br",
  contactWhatsappNumber: "+5511996636969",
  contactWhatsappMessage: "Olá, preciso de ajuda!",
  colors: {
    primary: "#26CAD3",
    secondary: "#6A4F9B",
    tertiary: "#F3EA9B",
    quaternary: "#0071B9",
    success: "#1fb535",
    info: "#3267d1",
    alert: "#cfe622",
    error: "#e01d3a",
    quinary: "#7AD06D",
    grey: "#7A7A7A",
    "light-grey": "#F9F9F9",
    "primary-lighten": "#b7edf0",
    "tertiary-lighten": "#fdfbeb",
  },
  logoutUrl: "/",
  flowMessages: {
    maritalStatus: {
      title: "Informe o seu estado civil",
      description:
        "A informação correta do seu estado civil é muito importante para darmos mais agilidade ao seu pedido de empréstimo.",
    },
    naturalness: {
      title: "Informe o seu local de nascimento",
      description: "",
    },
    sameBankAccount: {
      title: "Deseja utilizar a mesma conta que está em seu contra-cheque/holerite?",
      description:
        "Você pode utilizar a mesma conta bancária que consta em seu Holerite (contra-cheque) ou cadastrar uma nova conta bancária para receber o seu financiamento.",
    },
    personalReferences: {
      title: "Informe uma referência pessoal",
      description: "",
    },
    person: {
      title: "Você é uma pessoa politicamente exposta?",
      description: "",
    },
    relationship: {
      title: "Você possui algum relacionamento próximo com uma pessoa politicamente exposta?",
      description: "",
    },
    addressConfirmation: {
      title: "Informações de endereço",
      description: "",
    },
    analysis: {
      title:
        "Iremos realizar a análise final da proposta e em até 1 dia útil, retomaremos com o status. Você poderá acompanhar a proposta por aqui ou pelo e-mail que enviaremos.",
      description: `
          Em caso de dúvidas não hesite em entrar em contato pelo e-mail
          <span class="font-weight-bold"> contato@dinheirow.com.br </span>
        `,
    },
    status_analise_finalizada: finalizedAnalysis,
    endorsement: {
      title: `Estamos mais perto do que nunca!`,
      description: `Parabéns! A proposta já foi encaminhada ao banco para a análise final. O prazo para o
          recebimento é em média de 2 dias úteis, podendo sofrer variações em função do banco
          parceiro e da disponibilidade do sistema do governo. Porém não se preocupe, estamos
          acompanhando todo o processo e entraremos em contato após a análise final pelo banco.`,
    },
    finalizedAnalysis: finalizedAnalysis,
    awaitTransfer: {
      title: "Empréstimo aprovado!",
      description: `Seu dinheiro será transferido em breve. Em caso de dúvidas não hesite em entrar em contato pelo e-mail <span class="font-weight-bold">contato@dinheirow.com.br</span>`,
    },
  },
  specificRoutes: [
    {
      path: "/",
      name: "Landing",
      meta: { requiresAuth: false },
      component: () => import("@/pages/landing/Landing.vue"),
      alias: ["/bancoarbi"],
    },
    {
      path: "/cadastro",
      name: "Register",
      meta: { requiresAuth: false },
      component: () => import("@/pages/register/RegisterPage.vue"),
      children: [
        {
          path: "dalla",
          name: "RegisterDalla",
          meta: { requiresAuth: false },
          props: { name: "Dalla" },
          component: () => import("@/components/register/Register.vue"),
        },
        {
          path: "arbi",
          name: "RegisterArbi",
          meta: { requiresAuth: false },
          props: { name: "Arbi" },
          component: () => import("@/components/register/Register.vue"),
        },
      ],
    },
    {
      path: "/blog",
      name: "Blog",
      beforeEnter: () => {
        window.location.href = "https://blog.dinheirow.com.br";
      },
    },
    {
      path: "/blog/*",
      name: "BlogRedirect",
      component: () => import("@/pages/blog_redirect/BlogRedirect.vue"),
    },
  ],
  modalityCodes: ["pensionista_inss", "militar", "servidor_municipal", "servidor_estadual"],
  hideWhatsappIcon: false,
  messageSendingType: EMessageSendingType.SMS,
  borrowerCanCreateProposal: true,
  borrowerCanCreateUser: true,
  additionalConfigs: {
    showProposalValues: true,
  },
};

export default dinheirow;
